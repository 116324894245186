var exports = {};

function mkfragment(elements) {
  var frag = document.createDocumentFragment();

  for (var i = 0; i < elements.length; ++i) {
    frag.appendChild(elements[i]);
  }

  return frag;
}

;

exports.remove = function (el) {
  if (!el.parentNode) {
    return;
  }

  return el.parentNode.removeChild(el);
};

exports.replace = function (el, what) {
  if (!el.parentNode) {
    return;
  }

  return el.parentNode.replaceChild(mkfragment(what), el);
};

exports.prepend = function (el, what) {
  return el.insertBefore(mkfragment(what), el.firstChild);
};

exports.append = function (el, what) {
  var frag = document.createDocumentFragment();
  return el.appendChild(mkfragment(what));
}; // returns newly inserted element


exports.after = function (el, what) {
  if (!el.parentNode) {
    return;
  } // ie9 doesn't like null for insertBefore


  if (!el.nextSilbling) {
    return el.parentNode.appendChild(mkfragment(what));
  }

  return el.parentNode.insertBefore(mkfragment(what), el.nextSilbling);
};

exports.before = function (el, what) {
  if (!el.parentNode) {
    return;
  }

  return el.parentNode.insertBefore(mkfragment(what), el);
};

exports.empty = function (parent) {
  // cheap way to remove all children
  parent.innerHTML = "";
};

export default exports;