var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// whitespace regex to avoid creating every time
var re = /\s+/;
/**
 * Wrap `el` in a `ClassList`.
 *
 * @param {Element} el
 * @return {ClassList}
 * @api public
 */

exports = function (el) {
  return new ClassList(el);
};
/**
 * Initialize a new ClassList for `el`.
 *
 * @param {Element} el
 * @api private
 */


function ClassList(el) {
  (this || _global).el = el;
  (this || _global).list = el.classList;
}
/**
 * Add class `name` if not already present.
 *
 * @param {String} name
 * @return {ClassList}
 * @api public
 */


ClassList.prototype.add = function (name) {
  // classList
  if ((this || _global).list) {
    (this || _global).list.add(name);

    return this || _global;
  } // fallback


  var arr = this.array();
  var i = arr.indexOf(name);

  if (!~i) {
    arr.push(name);
  }

  (this || _global).el.className = arr.join(" ");
  return this || _global;
};
/**
 * Remove class `name` when present.
 *
 * @param {String} name
 * @return {ClassList}
 * @api public
 */


ClassList.prototype.remove = function (name) {
  // classList
  if ((this || _global).list) {
    (this || _global).list.remove(name);

    return this || _global;
  } // fallback


  var arr = this.array();
  var i = arr.indexOf(name);

  if (~i) {
    arr.splice(i, 1);
  }

  (this || _global).el.className = arr.join(" ");
  return this || _global;
};
/**
 * Toggle class `name`.
 *
 * @param {String} name
 * @return {ClassList}
 * @api public
 */


ClassList.prototype.toggle = function (name) {
  // classList
  if ((this || _global).list) {
    (this || _global).list.toggle(name);

    return this || _global;
  } // fallback


  if (this.has(name)) {
    return this.remove(name);
  }

  return this.add(name);
};
/**
 * Return an array of classes.
 *
 * @return {Array}
 * @api public
 */


ClassList.prototype.array = function () {
  var arr = (this || _global).el.className.split(re);

  if ("" === arr[0]) {
    arr.pop();
  }

  return arr;
};
/**
 * Check if class `name` is present.
 *
 * @param {String} name
 * @return {ClassList}
 * @api public
 */


ClassList.prototype.has = function (name) {
  return (this || _global).list ? (this || _global).list.contains(name) : !!~this.array().indexOf(name);
};

export default exports;