var exports = {};
var proto = Element.prototype;
var vendor = proto.matchesSelector || proto.webkitMatchesSelector || proto.mozMatchesSelector || proto.msMatchesSelector || proto.oMatchesSelector;

exports = function match(el, selector) {
  if (vendor) {
    return vendor.call(el, selector);
  }

  var nodes = el.parentNode.querySelectorAll(selector);

  for (var i = 0; i < nodes.length; ++i) {
    if (nodes[i] == el) {
      return true;
    }
  }

  return false;
};

export default exports;